/**
 * @generated SignedSource<<c7fa021a05cfa194325a1fc65cc29679>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusCode = "ABORTED" | "ALREADY_EXISTS" | "CANCELLED" | "DATA_LOSS" | "DEADLINE_EXCEEDED" | "FAILED_PRECONDITION" | "INTERNAL" | "INVALID_ARGUMENT" | "NOT_FOUND" | "OK" | "OUT_OF_RANGE" | "PERMISSION_DENIED" | "RESOURCE_EXHAUSTED" | "UNAUTHENTICATED" | "UNAVAILABLE" | "UNIMPLEMENTED" | "UNKNOWN" | "%future added value";
export type UpdateKOLConfigInput = {
  bdInCharge?: ReadonlyArray<string> | null;
  clearBDInCharge?: boolean | null;
  comment?: string | null;
  commissionRate?: number | null;
  commissionTier?: number | null;
  directCommissionRate?: number | null;
  id: string;
  indirectCommissionRate?: number | null;
};
export type UpdateKOLConfigMutation$variables = {
  input: UpdateKOLConfigInput;
};
export type UpdateKOLConfigMutation$data = {
  readonly updateKOLConfig: {
    readonly code: StatusCode;
    readonly message: string;
  };
};
export type UpdateKOLConfigMutation = {
  response: UpdateKOLConfigMutation$data;
  variables: UpdateKOLConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BaseStatus",
    "kind": "LinkedField",
    "name": "updateKOLConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateKOLConfigMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateKOLConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa1053facbf0a8d2ed7f6a1f26410318",
    "id": null,
    "metadata": {},
    "name": "UpdateKOLConfigMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateKOLConfigMutation(\n  $input: UpdateKOLConfigInput!\n) {\n  updateKOLConfig(input: $input) {\n    code\n    message\n  }\n}\n"
  }
};
})();

(node as any).hash = "6291a9e5e8f6ffceae53ddfe5ddd34cd";

export default node;
