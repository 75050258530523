import { TBdPerformance } from 'src/graphql'
import { DialogCommissionRate } from 'src/partials'
import { NumberUtils } from 'src/utils'

interface Actions {
  onFresh?: () => void
}

export const renderColumns = ({ onFresh }: Actions) => [
  {
    key: 'email',
    title: 'BD email',
    dataIndex: 'email'
  },
  {
    key: 'kolCommissionRate',
    title: 'Kol Commission Rate',
    dataIndex: 'kolCommissionRate',
    render: (val: TBdPerformance['kolCommissionRate'], record: TBdPerformance) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          isBd
          fieldChange="kolCommissionRate"
          accountID={record.id}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    key: 'kolDirectInviteCommissionRate',
    title: 'kol Direct Invite Commission Rate',
    dataIndex: 'kolDirectInviteCommissionRate',
    render: (val: TBdPerformance['kolDirectInviteCommissionRate'], record: TBdPerformance) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          isBd
          fieldChange="kolDirectInviteCommissionRate"
          accountID={record.id}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    key: 'kolIndirectInviteCommissionRate',
    title: 'Kol Indirect Invite Commission Rate',
    dataIndex: 'kolIndirectInviteCommissionRate',
    render: (val: TBdPerformance['kolIndirectInviteCommissionRate'], record: TBdPerformance) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          isBd
          fieldChange="kolIndirectInviteCommissionRate"
          accountID={record.id}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    key: 'institutionCommissionRate',
    title: 'Institution Commission Rate',
    dataIndex: 'institutionCommissionRate',
    render: (val: TBdPerformance['institutionCommissionRate'], record: TBdPerformance) => (
      <div className="fx fx-ai-center gap-2">
        <DialogCommissionRate
          isBd
          fieldChange="institutionCommissionRate"
          accountID={record.id}
          afterClose={(fresh) => fresh && onFresh?.()}
        >
          {val ? Number(val) / 100 + '%' : ''}
        </DialogCommissionRate>
      </div>
    )
  },
  {
    title: 'Adjusted KOLs Generated Fee',
    dataIndex: 'adjustedKOLGeneratedFee',
    key: 'adjustedKOLGeneratedFee',
    render: (val: TBdPerformance['adjustedKOLGeneratedFee']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Adjusted KOLs\' Direct Invite Generated Fee',
    dataIndex: 'adjustedKOLDirectInviteGeneratedFee',
    key: 'adjustedKOLDirectInviteGeneratedFee',
    render: (val: TBdPerformance['adjustedKOLDirectInviteGeneratedFee']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Adjusted KOLs\' Indirect Invite Generated Fee',
    dataIndex: 'adjustedKOLIndirectInviteGeneratedFee',
    key: 'adjustedKOLIndirectInviteGeneratedFee',
    render: (val: TBdPerformance['adjustedKOLIndirectInviteGeneratedFee']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'KOLs\' Direct Invite Profit Split',
    dataIndex: 'kolDirectInviteProfitSplit',
    key: 'kolDirectInviteProfitSplit',
    render: (val: TBdPerformance['kolDirectInviteProfitSplit']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'KOLs\' Indirect Invite Profit Split',
    dataIndex: 'kolIndirectInviteProfitSplit',
    key: 'kolIndirectInviteProfitSplit',
    render: (val: TBdPerformance['kolIndirectInviteProfitSplit']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Instis\' Earnings',
    dataIndex: 'adjustedInstitutionGeneratedFee',
    key: 'adjustedInstitutionGeneratedFee',
    render: (val: TBdPerformance['adjustedInstitutionGeneratedFee']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Instis\' Profit Split',
    dataIndex: 'institutionProfitSplit',
    key: 'institutionProfitSplit',
    render: (val: TBdPerformance['institutionProfitSplit']) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    key: 'bdEarnings',
    title: 'BD Earnings',
    dataIndex: 'bdEarnings',
    render: (val: TBdPerformance['bdEarnings'], record: TBdPerformance) => NumberUtils.formatNumber(Number(val) / 1e6, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  },
  {
    title: 'Number of KOL',
    dataIndex: 'noOfKOLInCharge',
    key: 'noOfKOLInCharge',
    render: (val: TBdPerformance['noOfKOLInCharge']) => Number(val)
  },
  {
    title: 'Number of Insti',
    dataIndex: 'noOfInstitutionInCharge',
    key: 'noOfInstitutionInCharge',
    render: (val: TBdPerformance['noOfInstitutionInCharge']) => Number(val)
  }
]
