import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { KolTrackerApi } from 'src/api'
import { ErrorMessage } from 'src/atoms'
import { useAsRef, useUnsubscribe, useValidation } from 'src/hooks'
import { MessageService } from 'src/services'
import { number, object } from 'yup'

type FieldType = {
  commissionRate: string
  comment: string
}

const schema = object().shape({
  commissionRate: number()
    .positive()
    .min(0)
    .max(100)
    .required()
    .typeError('Commission rate is required.')
    .label('Commission rate')
})

interface IProps
  extends Omit<ComponentProps<typeof Modal>, 'onOk' | 'afterClose'> {
  accountID?: string
  isBd?: boolean | null
  fieldChange:
  | 'commissionRate'
  | 'directCommissionRate'
  | 'indirectCommissionRate'
  | 'institutionCommissionRate'
  | 'kolCommissionRate'
  | 'kolDirectInviteCommissionRate'
  | 'kolIndirectInviteCommissionRate'
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}

export const DialogCommissionRate: FC<IProps> = ({
  accountID,
  isBd,
  fieldChange,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState<boolean>(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [formData, setFormData] = useState({
    commissionRate: '',
    comment: ''
  })

  const { validate, errors, reset } = useValidation({
    data: formData,
    schema
  })

  useEffect(() => {
    if (!invisible) {
      reset()
      setFormData({
        commissionRate: '',
        comment: ''
      })
    }
  }, [invisible, reset])

  const onSubmit = useCallback(async () => {
    if (!accountID) {
      return MessageService.error('Something went wrong!')
    }

    const { isValid, value: values } = await validate()
    if (!isValid) {
      return
    }

    const input: Parameters<typeof KolTrackerApi.updateKOLConfig>[0]['input'] = {
      id: accountID,
      [fieldChange]: Math.floor(Number(values?.commissionRate) * 100),
      comment: values?.comment
    }

    setLoading(true)
    const promise = isBd
      ? KolTrackerApi.updateBDConfig({ input })
      : KolTrackerApi.updateKOLConfig({ input })
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Commission rate changed successfully!')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [accountID, validate, fieldChange, isBd, unsubscribe$, afterCloseRef])

  return (
    <>
      <Button
        type="link"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Edit commission rate'}
      </Button>

      <Modal
        title={props.title || 'Edit commission rate'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={onSubmit}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            labelCol={{ span: 7 }}
            style={{ maxWidth: 600 }}
            onFinish={onSubmit}
            autoComplete="off"
            fields={[
              {
                name: ['comment'],
                value: formData.comment
              }
            ]}
          >
            <Form.Item<FieldType>
              label="Commission rate (%)"
              name="commissionRate"
              status={errors.hasError('commissionRate') ? 'error' : undefined}
            >
              <Input
                type="number"
                step={0.01}
                max={100}
                value={formData.commissionRate}
                onChange={(e) => setFormData((prev) => ({
                  ...prev,
                  commissionRate: e.target.value.replace(/((.*)\.([0-9]{2}))(.*)/, '$1')
                }))}
              />
              <ErrorMessage>{errors.getError('commissionRate')}</ErrorMessage>
            </Form.Item>

            <Form.Item<FieldType>
              label="Comment"
              name="comment"
            >
              <Input.TextArea
                value={formData.comment}
                onChange={(e) => setFormData((prev) => ({
                  ...prev,
                  comment: e.target.value
                }))}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
