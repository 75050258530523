export const renderColumns = () => [
  {
    title: 'Added time',
    dataIndex: 'added-time',
    key: 'added-time'
  },
  {
    title: 'Added by',
    dataIndex: 'added-by',
    key: 'added-by'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action'
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment'
  }
]
