import { graphql } from 'relay-runtime'

import { RemoveMFAMutation } from './__generated__/RemoveMFAMutation.graphql'

export type { RemoveMFAMutation }

export const removeMFAMutation = graphql`
  mutation RemoveMFAMutation($input: RemoveMFAInput!) {
    removeMFA(input: $input) {
      message
      code
    }
  }
`
