import { Badge, Collapse, CollapseProps } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { EAccountState } from 'src/constants'
import { TUser } from 'src/graphql'
import { useUnsubscribe } from 'src/hooks'
import { DialogChangeAccountState, UserChangelog } from 'src/partials'
import { AccountState } from 'src/partials/individual'
import { EPaths } from 'src/router'
import { BreadcrumbService, MessageService } from 'src/services'
import { EventUtils } from 'src/utils'
import { AccountDocuments } from '../../account-documents'
import { UserProfile } from '../../user-profile'
import { CompanyProfile } from './company-profile'
import { AccountStakeholders } from './stakeholders'
import { BusinessUserList } from './user-list'

export const BusinessDetail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [user, setUser] = useState<TUser | null>(null)
  const unsubscribe$ = useUnsubscribe()

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.CSR_BUSINESS,
        label: 'Business'
      },
      {
        route: [EPaths.CSR_BUSINESS_DETAIL, { id }],
        label: id
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  const fetchUser = useCallback(() => {
    if (!id) return

    from(UserApi.find(id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        })
      )
      .subscribe((user) => {
        setUser(user)
      })
  }, [id, unsubscribe$])

  const stateBadge = useMemo(() => {
    return {
      [EAccountState.ACTIVE]: 'success',
      [EAccountState.CLOSED]: 'error',
      [EAccountState.DEACTIVATED]: 'error',
      [EAccountState.ON_HOLD]: 'warning'
    }[user?.account.state as EAccountState] || undefined
  }, [user?.account.state])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Company profile',
      children: <CompanyProfile accountID={user?.account.id}/>
    },
    {
      key: '2',
      label: 'Contact person profile',
      children: <UserProfile user={user} onRefresh={fetchUser}/>
    },
    {
      key: '3',
      label: 'User list',
      children: <BusinessUserList user={user} onFresh={fetchUser}/>
    },
    {
      key: '4',
      label: 'Documents',
      children: <AccountDocuments accountID={user?.account.id}/>
    },
    {
      key: '5',
      label: 'Stakeholders',
      children: <AccountStakeholders accountID={user?.account.id}/>
    },
    {
      key: '6',
      label: (
        <div className="fx fx-ai-center gap-2">
          Account state
          {!!(user?.account.state && stateBadge) && (
            <Badge
              status={stateBadge as ComponentProps<typeof Badge>['status']}
              text={user.account.state}
            />
          )}
        </div>
      ),
      children: <AccountState user={user}/>,
      extra: (
        <div onClick={e => EventUtils.stopPropagation(e)}>
          <DialogChangeAccountState
            user={user}
            btnProps={{ size: 'small', type: 'link' }}
            afterClose={(fresh) => fresh && fetchUser?.()}
          >
            Edit
          </DialogChangeAccountState>
        </div>
      )
    },
    {
      key: '7',
      label: 'Admin change log',
      children: <UserChangelog user={user}/>
    }
  ]

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <section className="fx fx-column fx-extend">
      <Collapse
        items={items}
        defaultActiveKey={['1']}
      />
    </section>
  )
}
