/**
 * @generated SignedSource<<5b6ec4abc5a2fdf6260a197ca54f2190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type RaffleTaskType = "CONNECT_DISCORD" | "CONNECT_ELIGIBLE_WALLET" | "FIRST_KYC_DONE" | "FRIEND_INVITED" | "FRIEND_INVITED_2" | "FRIEND_INVITED_3" | "SHARE_LISTING_TWEET" | "SHARE_MAINNET_TWEET" | "SHARE_TWITTER_POST" | "TWITTER_FOLLOWED" | "TWITTER_LINKED" | "%future added value";
export type AccountRaffleInfoQuery$variables = {
  accountID: string;
};
export type AccountRaffleInfoQuery$data = {
  readonly accountRaffleInfo: {
    readonly data: {
      readonly currentBox: {
        readonly id: string;
        readonly name: string;
        readonly rarity: number;
      } | null;
      readonly doneTasks: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly ticketQuantity: number | null;
        readonly type: RaffleTaskType;
      }> | null;
      readonly totalTickets: number;
      readonly totalUsedTickets: number;
    };
  };
};
export type AccountRaffleInfoQuery = {
  response: AccountRaffleInfoQuery$data;
  variables: AccountRaffleInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "accountID"
      }
    ],
    "concreteType": "AccountRaffleStatus",
    "kind": "LinkedField",
    "name": "accountRaffleInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountRaffleData",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalTickets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalUsedTickets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MysteryBox",
            "kind": "LinkedField",
            "name": "currentBox",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rarity",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RaffleTask",
            "kind": "LinkedField",
            "name": "doneTasks",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ticketQuantity",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRaffleInfoQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountRaffleInfoQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c48733763f75398186174bb7f8b63b28",
    "id": null,
    "metadata": {},
    "name": "AccountRaffleInfoQuery",
    "operationKind": "query",
    "text": "query AccountRaffleInfoQuery(\n  $accountID: ID!\n) {\n  accountRaffleInfo(id: $accountID) {\n    data {\n      totalTickets\n      totalUsedTickets\n      currentBox {\n        id\n        name\n        rarity\n      }\n      doneTasks {\n        id\n        type\n        name\n        ticketQuantity\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "672bf5bcdc912e327e6a9226e8219657";

export default node;
