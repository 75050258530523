import { RewardAxios } from 'src/services'
import { TAxiosResponseData } from 'types/axios'

interface IUserTraderPointDistributionData {
  account_id: string
  giving_point: number
}

interface ITraderPointDistributionRequest {
  point_distributions: IUserTraderPointDistributionData[]
}

export interface ITraderPointDistributionData {
  account_id: string
  epoch_start_time: string
  total_point: number
}

interface ITraderPointDistributionsResponse {
  point_distributions: ITraderPointDistributionData[]
}

export class TraderRewardApi {
  static addTraderPoints(payload: ITraderPointDistributionRequest): TAxiosResponseData<any> {
    return RewardAxios.post('/api/admin/v1/trader/point-distributions/batch', payload)
  }

  static getListPointDistributions(): TAxiosResponseData<ITraderPointDistributionsResponse> {
    return RewardAxios.get('/api/admin/v1/trader/point-distributions')
  }
}
