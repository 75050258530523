import { Collapse, CollapseProps } from 'antd'
import { FC, useEffect } from 'react'
import { EcosystemPointDistribution, TraderPointDistribution } from 'src/partials'
import { EPaths } from 'src/router'
import { BreadcrumbService } from 'src/services'

export const KOLPointDistribution: FC = () => {
  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.BD_KOL_TRACKER,
        label: 'KOL Tracker'
      },
      {
        route: EPaths.BD_KOL_POINT_DISTRIBUTION,
        label: 'Point Distributions'
      }
    ]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Ecosystem Point',
      children: <EcosystemPointDistribution/>
    },
    {
      key: '2',
      label: 'Trader Point',
      children: <TraderPointDistribution/>
    }
  ]

  return (
    <section className="fx fx-column fx-extend">
      <Collapse items={items} destroyInactivePanel/>
    </section>
  )
}
