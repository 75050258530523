/**
 * @generated SignedSource<<1c512333ed55a3b7243d2a77be8b6118>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BDConfigurationClientType = "both" | "institution" | "kol" | "%future added value";
export type BDPerformanceInput = {
  epochID: string;
  pageNumber?: number | null;
  pageSize?: number | null;
  query?: string | null;
};
export type BdPerformanceQuery$variables = {
  input: BDPerformanceInput;
};
export type BdPerformanceQuery$data = {
  readonly bdPerformance: {
    readonly data: ReadonlyArray<{
      readonly adjustedInstitutionGeneratedFee: string;
      readonly adjustedKOLDirectInviteGeneratedFee: string;
      readonly adjustedKOLGeneratedFee: string;
      readonly adjustedKOLIndirectInviteGeneratedFee: string;
      readonly bdEarnings: string;
      readonly clientEarnings: string;
      readonly clientType: BDConfigurationClientType;
      readonly commissionRate: number;
      readonly email: string;
      readonly id: string;
      readonly institutionCommissionRate: number;
      readonly institutionProfitSplit: string;
      readonly kolCommissionRate: number;
      readonly kolDirectInviteCommissionRate: number;
      readonly kolDirectInviteProfitSplit: string;
      readonly kolIndirectInviteCommissionRate: number;
      readonly kolIndirectInviteProfitSplit: string;
      readonly noOfInstitutionInCharge: number;
      readonly noOfKOLInCharge: number;
      readonly numberOfClientsInCharge: number;
    }>;
    readonly total: number;
  };
};
export type BdPerformanceQuery = {
  response: BdPerformanceQuery$data;
  variables: BdPerformanceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BDPerformanceStatus",
    "kind": "LinkedField",
    "name": "bdPerformance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BDPerformance",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfClientsInCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientEarnings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bdEarnings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "institutionCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kolCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kolDirectInviteCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kolIndirectInviteCommissionRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustedKOLGeneratedFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustedKOLDirectInviteGeneratedFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustedKOLIndirectInviteGeneratedFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kolDirectInviteProfitSplit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kolIndirectInviteProfitSplit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustedInstitutionGeneratedFee",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "institutionProfitSplit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noOfKOLInCharge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noOfInstitutionInCharge",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BdPerformanceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BdPerformanceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "408ac4f00470f40c074883319efc94e9",
    "id": null,
    "metadata": {},
    "name": "BdPerformanceQuery",
    "operationKind": "query",
    "text": "query BdPerformanceQuery(\n  $input: BDPerformanceInput!\n) {\n  bdPerformance(input: $input) {\n    total\n    data {\n      id\n      email\n      clientType\n      commissionRate\n      numberOfClientsInCharge\n      clientEarnings\n      bdEarnings\n      institutionCommissionRate\n      kolCommissionRate\n      kolDirectInviteCommissionRate\n      kolIndirectInviteCommissionRate\n      adjustedKOLGeneratedFee\n      adjustedKOLDirectInviteGeneratedFee\n      adjustedKOLIndirectInviteGeneratedFee\n      kolDirectInviteProfitSplit\n      kolIndirectInviteProfitSplit\n      adjustedInstitutionGeneratedFee\n      institutionProfitSplit\n      noOfKOLInCharge\n      noOfInstitutionInCharge\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a62ebf95137790569adaf3f8184d1d4c";

export default node;
