import { Button, Form, Input, Modal } from 'antd'
import { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { EMPTY, catchError, finalize, from, switchMap, takeUntil } from 'rxjs'
import { UserApi } from 'src/api'
import { TUser } from 'src/graphql'
import { useAsRef, useUnsubscribe } from 'src/hooks'
import { AuthService, MessageService } from 'src/services'

type FieldType = {
  comment: string
}

export const DialogRemoveTOTP: FC<Omit<
  ComponentProps<typeof Modal>,
  'onOk' | 'afterClose'
> & {
  user: TUser
  btnProps?: ComponentProps<typeof Button>
  afterClose?: (saved?: boolean) => any
}> = ({
  user,
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [loading, setLoading] = useState(false)
  const [invisible, setInvisible] = useState(true)

  useEffect(() => {
    setInvisible(!props.open)
  }, [props.open])

  const afterCloseRef = useAsRef(props.afterClose)

  const [form] = Form.useForm<FieldType>()
  useEffect(() => {
    if (!invisible) {
      form.resetFields()
    }
  }, [invisible, form])

  const submit = useCallback((values: FieldType) => {
    if (!values.comment || !user.id) {
      return MessageService.error('Something went wrong')
    }

    setLoading(true)
    from(UserApi.removeMFA({
      input: {
        comment: values.comment,
        mfaType: 'TOTP',
        userID: user.id
      }
    }))
      .pipe(
        takeUntil(unsubscribe$),
        switchMap(() => from(AuthService.getProfile())),
        catchError((error) => {
          MessageService.error(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        MessageService.info('Remove TOTP successfully')
        setInvisible(true)
        afterCloseRef.current?.(true)
      })
  }, [user.id, afterCloseRef, unsubscribe$])

  return (
    <>
      <Button
        type="primary"
        {...btnProps}
        onClick={() => setInvisible(false)}
      >
        {props.children || 'Remove TOTP'}
      </Button>

      <Modal
        title={props.title || 'Remove TOTP'}
        open={!invisible}
        centered
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Ok"
        cancelText="Cancel"
        onOk={() => form.submit()}
        onCancel={() => setInvisible(true)}
      >
        {!invisible && (
          <Form
            form={form}
            layout="vertical"
            name="remove-totp"
            style={{ maxWidth: 600 }}
            onFinish={submit}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Comment"
              name="comment"
              rules={[{ required: true }]}
            >
              <Input.TextArea/>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}
