import { Table } from 'antd'
import moment from 'moment'
import { FC, useMemo } from 'react'
import { TUser } from 'src/graphql'
import { TzService } from 'src/services'
import { renderColumns } from './columns'

interface IProps {
  user?: TUser | null
}

export const UserChangelog: FC<IProps> = ({ user }) => {
  const dataSource = useMemo(
    () => user?.adminActionLogs?.sort(
      (log1, log2) => moment(log2.createTime).toDate().getTime() - moment(log1.createTime).toDate().getTime()
    ).map((log) => ({
      'added-time': TzService.format(log.createTime, 'HH:mm YYYY-MM-DD'),
      'added-by': log.adminPortalUser.email,
      action: log.action,
      comment: log.comment
    })) || [],
    [user?.adminActionLogs]
  )

  return (
    <section className="fx fx-column fx-extend">
      <Table
        bordered
        rowKey="id"
        className="fx-extend"
        pagination={false}
        dataSource={dataSource}
        columns={renderColumns()}
        scroll={{ x: true }}
      />
    </section>
  )
}
